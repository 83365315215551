.content-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 70px;
  margin: 0 0 50px 0;
}

.image-box {
  flex: 1;
  border-radius: 5px;
}
.image-box > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.desc-box {
  flex: 1.5;
}
.desc-box > .description {
  margin-top: 26px;
  color: #212427;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 161.182%; /* 25.789px */
}

.content-box:nth-child(even) {
  flex-direction: row-reverse;
}

.title {
  color: #00003a;
  font-family: Open Sans;
  font-size: 27px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media screen and (max-width: 1280px) {
  .desc-box > .description {
    line-height: 1.6;
  }
  .image-box {
    height: 350px;
  }
}

@media screen and (max-width: 1024px) {
  .image-box {
    height: 380px;
  }
  .desc-box > .description {
    margin-top: 22px;
    font-size: 14px;
  }
  .title {
    font-size: 24px;
  }
}
@media screen and (max-width: 992px) {
  .content-box:nth-child(even) {
    flex-direction: column;
  }
  .content-box {
    flex-direction: column;
    gap: 30px;
  }
  .title {
    font-size: 22px;
  }
}

.font-custom {
  font-weight: 30;
}
