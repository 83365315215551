.container {
    display: flex;
    padding-top: 2.5rem;
    padding-bottom: 4rem;
    margin-left: 2rem;
    margin-right: 2rem;
    flex-direction: column-reverse;
    gap: 2rem;
}

.bottom-container {
    display: flex;
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-left: 2rem;
    margin-right: 2rem;
    flex-direction: column;
    gap: 2rem;
}

@media (min-width: 500px) {
    .container {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}

@media (min-width: 992px) {
    .container {
        margin-left: 6rem;
        margin-right: 6rem;
        gap: 5rem;
    }
}

@media (min-width: 1200px) {
    .container {
        margin-left: 10rem;
        margin-right: 10rem;
        gap: 5rem;
    }
}

@media (min-width: 1350px) {
    .container {
        margin-left: 16rem;
        margin-right: 16rem;
        gap: 5rem;
    }
}

@media (min-width: 992px) {
    .container {
        flex-direction: row;
    }
}

.flex-description {
    flex: 2;
}

.description {
    font-size: 1rem;
    line-height: 1.5rem;
}


.category-image-container {
    height: auto;
    border-radius: 0.375rem;
}

@media (min-width: 500px) {
    .category-image-container {
        flex: 1.5;
    }
}

@media (min-width: 992px) {
    .category-image-container {
        flex: 1.5;
    }

    .category_main_sec_wrapper .top-container .category-image-container {
        margin-top: 20px;
    }
}

.custom-image {
    object-fit: contain;
    border-radius: 0.375rem;
    width: 100%;
    height: 100%;
}

@media (min-width: 992px) {
    .custom-image {
        object-fit: cover;
    }
}


@media (max-width: 992px) {
    .category-image-container {
        width: 70%;
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .category-image-container {
        width: 100%;
    }
}