* {
  font-family: "Open Sans", sans-serif !important;
}

.hero p {
  margin: 0px;
}

.section__partner__container {
  display: flex;
  margin: 0 8rem;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 0;
}

.section__partner__image-container {
  aspect-ratio: 16/9;
  max-height: 254px;
  margin-right: 6rem;
}

.section__partner__image-container>img {
  width: 100%;
}

.section__partner__flex-description {
  flex: 1;
  margin-right: 180px;
  font-size: 18px;
}

.reasons {
  font-family: "Open Sans", sans-serif;
}

.description {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
  color: #495057;
}

.image-container {
  flex: 1;
}

.partner-hero-desc {
  max-width: 58%;
}

.section__partner__description {
  width: 60%;
}

/* partner-slider */
.partner-slider .slick-dots li button:before {
  display: none;
}

.partner-slider .slick-dots li {
  width: 16px;
  height: 16px;
}

.partner-slider .slick-dots li button {
  padding: 0;
  background: #d9d9d9;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

.partner-slider .slick-dots li.slick-active button {
  background: #c4bbbb;
}

.partner-slider .slick-slide .reason {
  margin: 0 10px 25px 10px;
}

.partner-slider .slick-slide .reason .content {
  min-height: 200px;
}

@media (max-width:1300px) {
  .section__partner__image-container {
    margin-right: 0px;
  }

  .section__partner__flex-description {
    margin-right: 140px;
  }
}

@media (max-width:1199px) {
  .section__partner__container {
    margin: 0 4rem;
  }

  .section__partner__flex-description {
    margin-right: 100px;
  }
}

@media (max-width: 992px) {
  .section__partner__flex-description {
    margin-right: 0px;
    margin-bottom: 33px;
  }

  .section__partner__container {
    flex-direction: column;
  }

  .section__partner__description {
    width: 100%;
  }

  .section__partner__image-container {
    aspect-ratio: 16/9;
    max-height: initial;
    margin-right: 0;
  }

  .partner-hero-desc {
    max-width: 100%;
  }
}

@media (max-width: 702px) {
  .section__partner__container {
    margin: 0 1.5rem;
    padding: 3rem 0;
  }
}

@media (max-width: 500px) {

  .partner-hero-desc>span,
  .section__partner__description>span {
    font-size: 15px !important;
  }

  .partner-slider .slick-slide .reason .content {
    min-height: 272px;
  }
}

@media (max-width: 400px) {
  .partner-slider .slick-slide .reason .content {
    min-height: 320px;
  }
}