/* * {
  font-family: "Open Sans", sans-serif !important;
} */

.font-body {
  max-width: 1920px;
  position: relative;
  margin: 0 auto;
}

/* .homepage-mobile {
  background-image: linear-gradient(
      182deg,
      #00003a -154.02%,
      rgba(25, 25, 194, 0) 216.94%
    ),
    url(http://localhost:3000/static/media/homeMobile.b9de281….png);
  background-position: 50% 0;
  background-color: lightgray;
  background-size: cover;
  background-repeat: no-repeat;
} */

@media screen and (min-width: 1920px) {
  .home-card > div {
    width: auto !important;
  }
}
