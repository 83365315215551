ul li {
    padding: 10px 0px;
    margin-left: 23px;
}

.pro-cons-container {
    box-shadow: 10px 14px 16px rgba(20, 1, 1, 0.2);
}

.custom_max_btn {
    max-width: 192px;
    width: 100%;
}

.blog-content .custom_bg_pos:first-of-type {
    background-position: right top -65px;
}

@media (max-width:500px) {
    .blog-content .custom_bg_pos:first-of-type {
        background-position: right -40px top;
    }

    .custom_max_btn {
        max-width: 200px;
    }
}