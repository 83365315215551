.explore-box-bg::before {
    background: rgba(0, 0, 0, 0.4);
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
}
.dropdown-cat select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-image: url("../../src/images/dropdown-icon.svg");
}

.custom-slickslider .slick-prev:before, .custom-slickslider .slick-next:before{
    display: none;
}
.custom-slickslider .slick-prev, .custom-slickslider .slick-next {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0px -1px 13px 0px rgba(0, 0, 0, 0.15);
    background-color: #fff!important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    top: -70px;
    transform: translateY(0px);
    right: 0;
}
.custom-slickslider .slick-prev{
    left: auto;
    right: 46px;
}
.custom-slickslider .slick-prev svg, .custom-slickslider .slick-next svg{
    color:#4158dd;
}
.custom-slickslider .slick-prev.slick-disabled, .custom-slickslider .slick-next.slick-disabled{
    background-color: #eff4f8!important;
    cursor: not-allowed;
    opacity: 1;
}
.custom-slickslider .slick-prev.slick-disabled svg, .custom-slickslider .slick-next.slick-disabled svg{
    color: #fff;
}

@media (max-width:992px){
    .bloghome-herobg{
        background-position: right -230px center!important;
    }
}