/* custom-markdown-styles.css */
.custom-ul {
    padding-left: 0;
    list-style-type: none;
  }
  
  .custom-li {
    margin-top: 1rem;
    position: relative;
  }
  
  .custom-li::before {
    content: '\2713'; 
    color: #0A1E93; 
    margin-right: 8px; 
    font-size: 1.25rem;
    font-weight: bolder;
    position: absolute; 
    left: -1.5rem; 
  }
  